<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button />
      </div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <!-- <div class="search-bar input-group" @click="searchModalVisible = true"> -->
      <!--
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>
        -->
      <!-- <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
                <i class="tim-icons icon-zoom-split"></i>
            </button> -->
      <!-- You can choose types of search input -->
      <!-- </div> -->
      <!-- <modal :show.sync="searchModalVisible" class="modal-search" id="searchModal" :centered="false" :show-close="true">
            <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" />
        </modal>
        <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" title-classes="nav-link" class="nav-item">
            <template slot="title">
                <div class="notification d-none d-lg-block d-xl-block"></div>
                <i class="tim-icons icon-sound-wave"></i>
                <p class="d-lg-none">New Notifications</p>
            </template>
            <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Mike John responded to your email</a>
            </li>
            <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">You have 5 more tasks</a>
            </li>
            <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Your friend Michael is in town</a>
            </li>
            <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Another notification</a>
            </li>
            <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Another one</a>
            </li>
        </base-dropdown> -->
      <button class="btn cache-clear" @click="clearCache()">
        Limpar Cache
      </button>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template slot="title">
          <div class="photo"><img :src="user.avatar" /></div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Sair</p>
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">{{
            user.name.split(' ')[0]
          }}</a>
        </li>
        <!-- <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Settings</a>
            </li> -->
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" @click="logout()" class="nav-item dropdown-item">Sair</a>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from '@/components'
import SidebarToggleButton from './SidebarToggleButton'

import { mapState } from 'vuex'

export default {
  components: {
    SidebarToggleButton,
    BaseNav
  },
  computed: {
    routeName () {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    },
    isRTL () {
      return this.$rtl.isRTL
    },

    ...mapState('auth', ['user'])
  },
  data () {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    }
  },
  methods: {
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown () {
      this.activeNotifications = false
    },
    toggleSidebar () {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false)
    },
    toggleMenu () {
      this.showMenu = !this.showMenu
    },
    async logout () {
      await this.$jwt.logout()
      this.$router.push('/login')
    },
    async clearCache () {
      try {
        const status = (await this.$http.delete('cache')).status
        if (status === 200) {
          this.$notify({
            message: 'Cache limpo com sucesso, atualizando página...',
            timeout: 3000,
            icon: 'tim-icons icon-bell-55',
            type: 'success',
            horizontalAlign: 'left'
          })
          setTimeout(() => location.reload(), 2000)
        }
      } catch {
        this.$notify({
          message: 'Erro ao limpar cache.',
          timeout: 3000,
          icon: 'tim-icons icon-bell-55',
          type: 'danger',
          horizontalAlign: 'left'
        })
      }
    }
  }
}
</script>
<style scoped>
.top-navbar {
  top: 0px;
}

.cache-clear {
  height: 2rem;
  padding: 0.25rem 1.5rem;
  margin-top: 0.5rem;
  background: #d3d3d3;
  color: #27293d;
}

.cache-clear:hover {
  color: #fff !important;
}
</style>
