<template>
  <button
    class="minimize-sidebar btn btn-link btn-just-icon"
    @click="minimizeSidebar"
    rel="tooltip"
    data-original-title="Sidebar toggle"
    data-placement="right"
  >
    <i class="tim-icons icon-align-center visible-on-sidebar-regular"></i>
    <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i>
  </button>
</template>
<script>
export default {
  name: 'sidebar-toggle-button',
  methods: {
    minimizeSidebar () {
      // Remove this code if you don't want to display notifications while minimizing sidebar
      const isMinimizedText = this.$sidebar.isMinimized ? 'inativa' : 'ativa'
      this.$notify({
        type: 'primary',
        message: `Sidebar mini ${isMinimizedText}...`,
        icon: 'tim-icons icon-bell-55'
      })
      this.$sidebar.toggleMinimize()
      if (localStorage.themeconfig) {
        const theme = JSON.parse(localStorage.themeconfig)
        theme.sidebarMini = !theme.sidebarMini
        localStorage.setItem('themeconfig', JSON.stringify(theme))
      }
    }
  }
}
</script>
<style></style>
