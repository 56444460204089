export default [
  {
    name: 'início',
    role: 'admin',
    path: '/dashboard',
    icon: 'tim-icons icon-chart-pie-36'
  },
  {
    name: 'Usuários',
    icon: 'fas fa-users',
    role: 'admin',
    children: [
      {
        path: '/usuarios/admin',
        name: 'Administrador',
        role: 'admin'
      },
      {
        path: '/usuarios/company-master',
        name: 'Coletores',
        role: 'admin'
      },
      {
        path: '/usuarios/entidade',
        name: 'Entidades',
        role: 'admin'
      },

      {
        path: '/usuarios/gerador',
        name: 'Geradores',
        role: 'admin'
      },
      {
        path: '/usuarios/prefeitura',
        name: 'Prefeituras',
        role: 'admin'
      }
    ]
  },
  {
    name: 'Cidades Habilitadas',
    icon: 'fas fa-city',
    role: 'admin',
    path: '/cidade'
  },
  {
    name: 'Relatórios',
    icon: 'fas fa-print',
    role: 'admin',
    path: '/reports'
  },
  {
    name: 'Categorias',
    icon: 'fas fa-stream',
    role: 'admin',
    path: '/categorias'
  },
  {
    name: 'Materiais',
    icon: 'fas fa-recycle',
    role: 'admin',
    path: '/materiais'
  },
  {
    name: 'Sugestões de Materiais',
    icon: 'fas fa-database',
    role: 'admin',
    path: '/sugestoes'
  },
  {
    name: 'Unidades de Medida',
    icon: 'fas fa-balance-scale',
    role: 'admin',
    path: '/unidades-de-medida'
  },
  {
    name: 'Banners',
    icon: 'fas fa-image',
    role: 'admin',
    path: '/banners'
  },
  {
    name: 'Bancos',
    icon: 'fas fa-university',
    role: 'admin',
    path: '/bancos'
  },
  {
    name: 'Financeiro',
    icon: 'fas fa-dollar-sign',
    role: 'admin',
    children: [
      {
        name: 'Aprovação',
        role: 'admin',
        path: '/aprovar-transacoes'
      },
      {
        name: 'Créditos comprados',
        role: 'admin',
        path: '/creditos-comprados'
      },
      {
        name: 'Entidade Saque',
        role: 'admin',
        path: '/saques-entidade'
      },
      {
        name: 'Gerador Saque',
        role: 'admin',
        path: '/saques-gerador'
      }
    ]
  },
  {
    name: 'E-mails',
    icon: 'fas fa-envelope',
    role: 'admin',
    path: '/emails'
  }
]
