<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{ show: isOpen }">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{ show: isOpen }">
        <li class="header-title">Cor da Sidebar</li>
        <li class="adjustments-line">
          <a class="switch-trigger background-color">
            <div class="badge-colors text-center">
              <span
                v-for="item in sidebarColors"
                :key="item.color"
                class="badge filter"
                :class="[`badge-${item.color}`, { active: item.active }]"
                :data-color="item.color"
                @click="changeSidebarBackground(item)"
              ></span>
            </div>
            <div class="clearfix"></div>
          </a>
        </li>

        <li class="header-title">Sidebar Mini</li>
        <li class="adjustments-line">
          <div class="togglebutton switch-sidebar-mini">
            <span class="label-switch">INATIVO</span>
            <base-switch
              v-model="sidebarMini"
              @input="minimizeSidebar"
            ></base-switch>
            <span class="label-switch label-right">ATIVO</span>
          </div>

          <div class="togglebutton switch-change-color mt-3">
            <span class="label-switch">TEMA CLARO</span>
            <base-switch v-model="darkMode" @input="toggleMode"></base-switch>
            <span class="label-switch label-right">TEMA ESCURO</span>
          </div>
        </li>

        <li class="button-container mt-4"></li>
      </ul>
    </div>
  </div>
</template>
<script>
import { BaseSwitch } from '@/components'

export default {
  name: 'sidebar-share',
  components: {
    BaseSwitch
  },
  props: {
    backgroundColor: String
  },
  data () {
    return {
      sidebarMini: true,
      darkMode: false,
      isOpen: false,
      sidebarColors: [
        {
          color: 'primary',
          active: false,
          value: 'primary'
        },
        {
          color: 'info',
          active: false,
          value: 'blue'
        },
        {
          color: 'success',
          active: false,
          value: 'green'
        },
        {
          color: 'warning',
          active: false,
          value: 'orange'
        },
        {
          color: 'danger',
          active: false,
          value: 'red'
        }
      ],
      sidebarColor: {
        color: 'primary',
        active: false,
        value: 'primary'
      }
    }
  },
  methods: {
    toggleDropDown () {
      this.isOpen = !this.isOpen
    },
    closeDropDown () {
      this.isOpen = false
    },
    toggleList (list, itemToActivate) {
      list.forEach(listItem => {
        listItem.active = false
      })
      itemToActivate.active = true
    },
    changeSidebarBackground (item) {
      this.sidebarColor = item
      this.saveTheme()
      this.$emit('update:backgroundColor', item.value)
      this.toggleList(this.sidebarColors, item)
    },
    toggleMode (type) {
      const docClasses = document.body.classList
      if (type) {
        docClasses.remove('white-content')
      } else {
        docClasses.add('white-content')
      }
      this.saveTheme()
    },
    minimizeSidebar () {
      this.saveTheme()
      this.$sidebar.toggleMinimize()
    },
    getThemeConfig () {
      if (localStorage.themeconfig) {
        const theme = JSON.parse(localStorage.themeconfig)
        this.sidebarMini = theme.sidebarMini
        this.darkMode = theme.darkMode
        this.sidebarColor = theme.sidebarColor

        this.toggleMode(this.darkMode)
        if (!this.sidebarMini) this.minimizeSidebar()
        if (theme.sidebarColor) this.changeSidebarBackground(this.sidebarColor)
      } else {
        this.toggleMode()
        this.saveTheme()
      }
    },
    saveTheme () {
      const theme = {
        sidebarMini: this.sidebarMini,
        darkMode: this.darkMode,
        sidebarColor: this.sidebarColor
      }
      localStorage.setItem('themeconfig', JSON.stringify(theme))
    }
  },
  mounted () {
    this.getThemeConfig()
  }
}
</script>
<style scoped lang="scss">
@import '~@/assets/sass/dashboard/custom/variables';

.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}
</style>
