var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[_vm._l((_vm.menu),function(item,index){return [_c('sidebar-item',{key:index,attrs:{"link":{
            name: item.name,
            icon: item.icon,
            path: item.path || '#'
          }}},[_vm._l((item.children),function(child,children){return [_c('sidebar-item',{key:children,attrs:{"link":{
                name: child.name,
                icon: child.icon,
                path: child.path
              }}})]})],2)]})],2),(_vm.user.status === 3)?[_c('p',{staticClass:"complete_register"},[_vm._v(" Complete seu cadastro para ter acesso a todo o sistema. ")])]:_vm._e()],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }